import {default as BaseAutocompleteController} from '../lib/autocomplete-controller'

export default class extends BaseAutocompleteController {

    commit(selected) {
        super.select(selected);

        const url = selected.getAttribute("data-url");
        if (url && window.location) {
            window.location.replace(url);
        }
    }
}